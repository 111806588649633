import { IPriceDto, IPriceGrid } from '@/interfaces/models/price';
import { BaseAPI } from './base.api';

class PriceAPI extends BaseAPI {
  controller = '/price';

  add(data: IPriceDto) {
    return this._post<boolean>('', { data });
  }

  get() {
    return this._get<IPriceDto>('');
  }

  getGrid() {
    return this._get<IPriceGrid>('/grid');
  }

  applicationCost(data: { price: number; area: number; includeVAT?: boolean }) {
    return this._post<number>('/applicationCost', { data });
  }

  getFareCost(data: { price: number; distance: number; includeVAT?: boolean }) {
    return this._post<number>('/getFareCost', { data });
  }

  getIdleCost(data: {
    price: number;
    idleHours: number;
    includeVAT?: boolean;
  }) {
    return this._post<number>('/getIdleCost', { data });
  }

  getVATfromPrice(data: { value: number }) {
    return this._post<number>('/getVATfromPrice', { data });
  }

  priceWithVAT(data: { value: number }) {
    return this._post<number>('/priceWithVAT', { data });
  }
}

const api = new PriceAPI();
export default api;
