import {
  EBillStatus,
  ETransactionOperation,
  ETransactionStatus,
} from './enums';
import { IOrder } from './models/order';
import { IRequest } from './models/request';

export enum EStatuses {
  Active = 'active',
  Inactive = 'inactive',
  Deleted = 'deleted',
  Exchange = 'exchange',
}

export interface IEntity {
  uid: any;
  name: string;
  updatedAt?: string;
}

export interface IStatus {
  code: EStatuses;
  description: string;
  name: string;
}

export interface IPerformanceRange {
  max: number;
  min: number;
  uid: string;
  value: number;
}

export interface IUser extends IEntity {
  password: string;
  company: string;
  email: string;
  firstName: string;
  isValidated: boolean;
  language: string;
  lastName: string;
  middleName: string;
  phone: string;
  uid: string;
  createdAt: string;
  updatedAt: string;
  isApproved: boolean;
  isClient: boolean;
  isPartner: boolean;
  isAdministrator: boolean;

  companyBankName: string;
  companyDirectorFirstName: string;
  companyDirectorLastName: string;
  companyDirectorMiddleName: string;
  companyEDRPOU: string;
  companyIBAN: string;
  companyLegalAddress: string;
  companyLegalName: string;
  companyMFO: string;
  companyImageUrl: null;
  companyType: IEntity;
}

export interface IProfileSettings {
  orderMinimalAreaPercent: number;
  orderMaximalAreaOverheadPercent: number;
  orderPrePaymentPercent: number;
  orderPartnerPrePaymentPercent: number;
  platformFeePercent: number;
  applicationRateMin: number;
  applicationRateMax: number;
  workingHoursPerDay: number;
  timeZone: string;
  defaultLanguage: string;
}

export interface IDron extends IEntity {
  imageUrl: string;
  name: string;
  droneVendorId: string;
  droneVendor: IEntity;
  droneModelId: string;
  droneModel: IEntity;
  performanceRangeId: string;
  performanceRange: IPerformanceRange;
  serialNumber: string;
  fuelTankSize: number;
  commissioningAt: string;
  commissioningWorkHours: number;
  servicePeriod: number;
  status: string | IStatus;
  maxPerformance: number;
  minPerformance: number;
  updatedAt: string;
}

export interface ICar extends IEntity {
  imageUrl: string;
  name: string;
  carMakeId: string;
  carModelId: string;
  colorId: string;
  carFuelTypeId: string;
  modelYear: number;
  VIN: string;
  registrationNumber: string;
  dronesCapacity: number;
  insuranceIsActive: boolean;
  insuranceExpiresAt: string;
  hasTrailer: boolean;
  status: string | IStatus;

  carFuelType: IEntity;
  carMake: IEntity;
  carModel: IEntity;
  color: IEntity;
  updatedAt: string;
}

export interface IStaff extends IEntity {
  imageUrl: string;
  firstName: string;
  lastName: string;
  middleName: string;
  positionId: string;
  contractTypeId: string;
  qualificationId: string;
  phone: string;
  email: string;
  passport: string;
  status: string | IStatus;
  updatedAt: string;

  contractType: IEntity;
  position: IEntity;
  qualification: IEntity;
}

export interface ILocation extends IEntity {
  imageUrl: string;
  address: string;
  latitude: number;
  longitude: number;
  status: string | IStatus;
  updatedAt: string;
}

export interface IMapLocation {
  lat?: number;
  lng?: number;
  address?: string;
}

export interface ICrew extends IEntity {
  imageUrl: string;
  name: string;
  locationId: string;
  carId: string;
  performanceRangeId: string;
  drones: string[];
  staff: string[];
  status: string | IStatus;
  updatedAt: string;
  car: ICar;
  location: ILocation;
  performanceRange: IPerformanceRange;
  dronesCount: number;
  staffCount: number;
}

export interface IBooking {
  date: string;
  isManualScheduled: boolean;
}

export interface IBill {
  amount: string;
  status: EBillStatus;
  updatedAt: string;
}

export interface IDocument extends IEntity {
  filename: string;
  filesize: number;
  isSignRequired: boolean;
  isSignedByPlatform: boolean;
  isSignedByUser: boolean;
  title: string;
  type: string;
  uid: string;
  updatedAt: string;
  uri: string;
  id: number;
  orderId: string;
  relatedDocumentId: string;
  requestId: string;
  userId: number;
  request: IRequest;
  order: IOrder;
  bill: IBill;
}

export interface ITransaction extends IEntity {
  id: number;
  amount: string;
  feeAmount: string;
  memo: string;
  operation: ETransactionOperation;
  order: IOrder;
  request: IRequest;
  status: ETransactionStatus;
}
